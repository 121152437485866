<template>
    <div v-if="dialog" class="relative z-50">
        <div class="fixed inset-0 bg-black bg-opacity-80"></div>
        <div class="fixed inset-0 overflow-auto">
            <div class="flex items-center justify-center h-full">
                <div class="bg-white rounded-lg p-4 w-full max-w-sm">
                    <div>
                        <div class="text-lg font-bold">{{ title }}</div>
                        <div class="text-gray-500 font-medium mt-2">
                            <ul v-if="Object.keys(cancelPolicy) != 0" class="list-disc pl-6">
                                <li>We will charge a cancellation fee of {{cancelPolicy.persen1}}% if booking is cancelled {{cancelPolicy.jam1}} hour or less before the event.</li>
                                <li>We will charge a cancellation fee of {{cancelPolicy.persen2}}% if booking is cancelled {{cancelPolicy.jam2}} hour or less before the event.</li>
                                <li>We will charge a cancellation fee of {{cancelPolicy.persen3}}% if booking is cancelled {{cancelPolicy.jam3}} hour or less before the event.</li>
                                <li>We will charge a cancellation fee of {{cancelPolicy.persen4}}% if booking is cancelled {{cancelPolicy.jam4}} hour or less before the event.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="flex mt-6 justify-between">
                        <button
                            @click="cancel"
                            class="bg-gray-200 hover:bg-gray-300 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg"
                        >
                            {{ button }}
                        </button>
                        <div v-if="confirmation">
                        <button
                            @click="confirm"
                            class="bg-primary text-white font-semibold py-2 px-6 rounded-lg"
                        >
                            Confirm Cancel
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                title: '',
                cancelPolicy: {},
                button: '',
                confirmation: false
            };
        },
        async created() {
            var res = await this.$http.get(`${this.$apiTripweWeb}/config/cancelation`);
            this.cancelPolicy = res.data.data;
        },
        methods: {
            open({ title, button, confirmation }) {
                this.title = title ?? 'Cancelation Policy';
                this.button = button ?? 'OK';
                this.confirmation = confirmation ?? false;
                this.dialog = true;

                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                });
            },
            confirm() {
                this.resolvePromise(true);
                this.dialog = false;
            },
            cancel() {
                this.resolvePromise(false);
                this.dialog = false;
            }
        }
    };
</script>
